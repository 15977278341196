import {useQuery} from '@apollo/client';
import {
    MeVyjadrovaciLinkaProfilArgs,
    Query,
    VyjadrovaciLinkaOdstavka,
    VyjadrovaciLinkaOdstavkaFormularTyp,
    VyjadrovackaSubjekt,
} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {AccountCircleOutlined, ArrowBackOutlined, LoginOutlined, LogoutOutlined, NoAccountsOutlined, RestartAltOutlined} from '@mui/icons-material';
import {
    AppBar,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Step,
    StepLabel,
    Stepper,
    Toolbar,
    Typography,
} from '@mui/material';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {FC, MouseEvent, ReactNode, useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {isLogged, redirect} from '../../../auth/service/LoginService';
import {Store} from '../../../lib/StoreType';
import {apolloClient} from '../../../lib/apolloClient';
import {theme} from '../../../lib/theme';
import {NotificationType} from '../../../models/notification/NotificationModel';
import {useCommonAction} from '../../action/CommonAction';
import {PageRoute, formMapPath} from '../../constants';
import {CommonQueries} from '../../graphql/CommonQueries';
import {Div} from '../../styledComponents/Div';
import {Zahranici} from '../../types';
import {FormKind} from '../../utils/CommonTypes';
import {isNotNullOrUndefinedOrEmpty, sanitizeHTML, transformPath, useMatches, useTablet} from '../../utils/CommonUtils';
import {redirectURI} from '../../utils/EnvironmentUtils';
import {LoadingDialog} from '../dialogs/LoadingDialog';
import {TooltipIcon} from '../helperComponents/TooltipIcon';
import {useAddNotification} from '../notifications/actions/NotificationsActions';
import {NotificationsComponent} from '../notifications/components/NotificationsComponent';
import {EgdLogo} from './EgdLogo';
import {FormOff} from './FormOff';
import {GasLogo} from './GasLogo';

const publicRuntimeConfig = getConfig().publicRuntimeConfig;

const OsobniUdajeZakaznikDialog = dynamic(() =>
    import('../../../modules/stepTwo/components/OsobniUdajeZakaznikDialog').then((mod) => mod.OsobniUdajeZakaznikDialog),
);

type Props = {
    readonly statusCode?: number;
    readonly children: ReactNode;
};

export const PageLayout: FC<Props> = ({children}) => {
    // local state
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
    const [isOpenUserDialog, setIsOpenUserDialog] = useState(false);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);

    // local variables
    const tablet = useTablet();
    const matches = useMatches();

    // redux state
    const {notifications} = useSelector((state: Store) => state?.notifications);
    const {activeStep, formSuccessSteps, loginSuccess, selfcareLogin, komodita, formKind} = useSelector((state: Store) => state?.common);
    const formData = useSelector((state: Store) => state.common.formData);

    // 3rd methods
    const {
        pathname,
        events,
        query: {shortcut},
    } = useRouter();
    const {closeNotification, addNotification} = useAddNotification();
    const {setFormData, setActiveStep, logout, resetFormData, setSuccessStep, backToD24, setFormKind} = useCommonAction();

    // query
    const {loading: loadingOdstavka, refetch: refetchOdstavka} = useQuery<Query>(CommonQueries.gql.odstavka, {
        fetchPolicy: 'no-cache',
        variables: {
            komodita: komodita,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (res) => {
            const sopWebFormOutages = res?.vyjadrovaciLinka?.odstavka.filter(
                ({typFormulare}) => typFormulare === VyjadrovaciLinkaOdstavkaFormularTyp.VYJADROVACKA,
            );

            const formOff = sopWebFormOutages?.reduce((sum, acc) => {
                if (!sum[acc.typFormulare]) {
                    sum[acc.typFormulare] = {} as {[key in Komodita]: VyjadrovaciLinkaOdstavka};
                }
                sum[acc.typFormulare][acc.komodita] = acc;
                return sum;
            }, {} as FormKind);
            setFormKind(formOff);
        },
    });
    const {
        loading: loadingProfil,
        data: dataProfil,
        refetch: refetchProfil,
    } = useQuery<Query, MeVyjadrovaciLinkaProfilArgs>(CommonQueries.gql.profil, {
        fetchPolicy: 'no-cache',
        onCompleted: (res) => {
            res?.me?.vyjadrovaciLinkaProfil &&
                !formData?.zadatel?.jmeno &&
                setFormData({
                    zadatel: {
                        ...res?.me?.vyjadrovaciLinkaProfil,
                        email: res?.me?.vyjadrovaciLinkaProfil?.email ?? '',
                        jmeno: res?.me?.vyjadrovaciLinkaProfil?.jmeno ?? '',
                        prijmeni: res?.me?.vyjadrovaciLinkaProfil?.prijmeni ?? '',
                        subjekt: res?.me?.vyjadrovaciLinkaProfil?.subjekt ?? VyjadrovackaSubjekt.FYZICKA_OSOBA,
                        zahranici: res?.me?.vyjadrovaciLinkaProfil?.adresa?.stat ? Zahranici.ANO : Zahranici.NE,
                        telefon: res?.me?.vyjadrovaciLinkaProfil?.telefon ?? '+420',
                        adresa: res?.me?.vyjadrovaciLinkaProfil?.adresa ?? formData?.zadatel?.adresa,
                    },
                });
        },
        skip: !isLogged(),
        variables: {
            komodita: komodita ?? Komodita.ELEKTRINA,
        },
    });

    // local variables
    const odstavitElektrina = formKind?.VYJADROVACKA?.ELEKTRINA?.komodita === komodita && !formKind?.VYJADROVACKA?.ELEKTRINA?.aktivni;
    const odstavitPlyn = formKind?.VYJADROVACKA?.PLYN?.komodita === komodita && !formKind?.VYJADROVACKA?.PLYN?.aktivni;
    const odstavka = odstavitElektrina || odstavitPlyn;
    const isProfilFilled =
        (dataProfil?.me?.vyjadrovaciLinkaProfil?.jmeno && dataProfil?.me?.vyjadrovaciLinkaProfil?.prijmeni) ||
        dataProfil?.me?.vyjadrovaciLinkaProfil?.nazevFirmy;
    const profil = isProfilFilled ? dataProfil?.me?.vyjadrovaciLinkaProfil : dataProfil?.me?.ucet;
    const path = transformPath(pathname, komodita, !odstavka);
    const showLoginInHeader = activeStep === 1 || activeStep === 0;
    const prekresleniPath = pathname === '/zadostOPrekresleni' || pathname === '/dokonceniZadostiOPrekresleni';

    // local methods
    const handleRouteEGD = () => redirect({pathname: 'https://www.egd.cz'});
    const handleRouteGAS = () => redirect({pathname: 'https://www.gasd.cz'});
    const handleRefresh = useCallback(() => {
        refetchOdstavka();
        refetchProfil();
    }, [refetchOdstavka, refetchProfil]);
    const handleOnOpenUserMenu = (e: MouseEvent<HTMLButtonElement>) => {
        setIsOpenUserMenu(true);
        setUserMenuAnchorEl(e.currentTarget);
    };
    const handleOnCloseUserMenu = () => setIsOpenUserMenu(false);
    const handleOnOpenDetailDialog = () => {
        handleOnCloseUserMenu();
        setIsOpenUserDialog(true);
    };
    const handleOnCloseDetailDialog = () => {
        handleRefresh();
        setIsOpenUserDialog(false);
    };
    const handleOnClickLogout = () => {
        logout(apolloClient);
    };
    const handleOnClickReset = () => {
        if (isNotNullOrUndefinedOrEmpty(redirectURI)) {
            const uri = `${redirectURI}/login?reset=true`;
            redirect(uri);
        }
    };
    const handleOnClickLogin = () => {
        if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.FRONTEND_IDM_ENDPOINT)) {
            const uri = `${publicRuntimeConfig.FRONTEND_IDM_ENDPOINT}/?redirect_uri=${publicRuntimeConfig.VYJADROVACI_LINKA_REDIRECT_URI}&client_id=${publicRuntimeConfig.VYJADROVACI_LINKA_CLIENT_ID}&scope=vyjadrovacky_web&response_type=code&app=vyjadrovacky`;
            redirect(uri);
        }
    };

    const handleBackToSelfcare = () => backToD24(apolloClient);

    // side effects
    useEffect(() => {
        if (
            !formSuccessSteps?.[activeStep - 1] &&
            pathname !== PageRoute.VSTUP &&
            pathname !== '/oauth/callback' &&
            !(pathname === PageRoute.OSOBNI_UDAJE && shortcut === 'true')
        ) {
            const step = formMapPath(formSuccessSteps?.[activeStep] ? activeStep : 0);
            redirect({pathname: step ?? pathname}).then(() =>
                addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.page.pathname" />}),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, formSuccessSteps, shortcut]);

    useEffect(() => {
        if (pathname === PageRoute.VSTUP && !loginSuccess) {
            setActiveStep(0);
            resetFormData(komodita);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (shortcut === 'true') {
            setActiveStep(1);
            setSuccessStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortcut]);

    useEffect(() => {
        events.on('routeChangeStart', handleRefresh);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            events.off('routeChangeStart', handleRefresh);
        };
    }, [events, handleRefresh]);

    return (
        <Div
            id="body"
            sx={{
                backgroundColor: 'background.default',
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            {/* Notifications */}
            <NotificationsComponent notifications={notifications} onClose={closeNotification} />

            {/* Header */}
            <Div
                sx={{
                    height: 80,
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    margin: '0 auto',
                    maxWidth: 1680,
                }}
            >
                <IconButton
                    sx={{
                        marginLeft: 5.5,
                    }}
                    data-testid={`egd-logo-button`}
                    onClick={komodita === Komodita.ELEKTRINA ? handleRouteEGD : handleRouteGAS}
                >
                    {komodita === Komodita.ELEKTRINA && <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} />}
                    {komodita === Komodita.PLYN && <GasLogo width={150} height={50} pathFill={'inherit'} />}
                </IconButton>
                {komodita && (
                    <Div sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton color="inherit" onClick={handleOnOpenUserMenu}>
                            {isLogged() && loginSuccess ? (
                                <AccountCircleOutlined sx={{fontSize: 35}} />
                            ) : (
                                <TooltipIcon text="button.login.tooltip" showIcon={false}>
                                    <NoAccountsOutlined sx={{fontSize: 35}} />
                                </TooltipIcon>
                            )}
                        </IconButton>
                        {isLogged() && loginSuccess && !loadingOdstavka && !loadingProfil && profil && (
                            <Typography variant="h6" data-testid={`appbar-logged-name`}>
                                {profil?.jmeno ? `${profil?.jmeno} ${profil?.prijmeni}` : 'nazevFirmy' in profil ? profil?.nazevFirmy : ''}
                            </Typography>
                        )}
                    </Div>
                )}
                <Popper
                    id="simple-menu"
                    sx={{
                        zIndex: 3000,
                    }}
                    anchorEl={userMenuAnchorEl}
                    open={isOpenUserMenu}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleOnCloseUserMenu}>
                            <MenuList>
                                {isLogged() && profil && loginSuccess ? (
                                    <MenuList>
                                        <MenuItem data-testid={`profil-button`} onClick={handleOnOpenDetailDialog}>
                                            <ListItemIcon>
                                                <AccountCircleOutlined />
                                            </ListItemIcon>
                                            <Typography>
                                                {profil?.jmeno ? `${profil?.jmeno} ${profil?.prijmeni}` : 'nazevFirmy' in profil ? profil?.nazevFirmy : ''}
                                            </Typography>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem data-testid={`reset-button`} onClick={handleOnClickReset}>
                                            <ListItemIcon>
                                                <RestartAltOutlined />
                                            </ListItemIcon>
                                            <FormattedMessage id="button.reset" />
                                        </MenuItem>
                                        {selfcareLogin && (
                                            <MenuItem data-testid={`selfcare-button`} onClick={handleBackToSelfcare}>
                                                <ListItemIcon>
                                                    <ArrowBackOutlined />
                                                </ListItemIcon>
                                                <FormattedMessage id="selfcare" />
                                            </MenuItem>
                                        )}
                                        <MenuItem data-testid={`logout-button`} onClick={handleOnClickLogout}>
                                            <ListItemIcon>
                                                <LogoutOutlined />
                                            </ListItemIcon>
                                            <FormattedMessage id="button.logout" />
                                        </MenuItem>
                                    </MenuList>
                                ) : (
                                    <div>
                                        {showLoginInHeader && (
                                            <MenuItem data-testid={`login-button`} onClick={handleOnClickLogin}>
                                                <ListItemIcon>
                                                    <LoginOutlined />
                                                </ListItemIcon>
                                                <FormattedMessage id="button.login" />
                                            </MenuItem>
                                        )}
                                        {selfcareLogin && (
                                            <MenuItem data-testid={`selfcare-button`} onClick={handleBackToSelfcare}>
                                                <ListItemIcon>
                                                    <ArrowBackOutlined />
                                                </ListItemIcon>
                                                <FormattedMessage id="selfcare" />
                                            </MenuItem>
                                        )}
                                    </div>
                                )}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Div>

            {/* Appbar */}
            <AppBar
                position="relative"
                color="secondary"
                sx={(theme) => ({
                    ...(komodita === Komodita.ELEKTRINA && {
                        backgroundImage: "url('/static/images/AppBarBackground.svg')",
                        backgroundPosition: 'right',
                        backgroundRepeat: 'no-repeat',
                        display: 'block',
                        minHeight: 204,
                    }),
                    ...(komodita !== Komodita.ELEKTRINA && {
                        display: !komodita ? 'flex' : 'block',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.secondary.main,
                        minHeight: 204,
                    }),
                })}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        margin: '0 auto',
                        maxWidth: 1680,
                    }}
                >
                    <Typography
                        variant="body2"
                        color="inherit"
                        sx={{
                            marginTop: 2,
                        }}
                        component="div"
                    >
                        <div dangerouslySetInnerHTML={{__html: sanitizeHTML(path)}} />
                    </Typography>
                    <Typography
                        color="inherit"
                        variant={matches ? 'h4' : !komodita && !tablet ? 'h1' : !komodita && tablet ? 'h2' : 'h3'}
                        sx={{marginTop: {xs: 0, md: 2.5}, alignSelf: !komodita ? 'center' : 'auto'}}
                    >
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage
                                id="portal"
                                values={{komodita: komodita === Komodita.ELEKTRINA ? ' - Elektřina' : komodita === Komodita.PLYN ? ' - Plyn' : ''}}
                            />
                        </Box>
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* Zobrazení stepperu */}
            {!odstavka && !prekresleniPath && komodita && (
                <Stepper
                    sx={{
                        backgroundColor: 'background.default',
                        maxWidth: 1450,
                        margin: 'auto',
                        marginTop: 3,
                        overflowX: matches ? 'auto' : 'hidden',
                    }}
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {Object.keys(PageRoute)?.map((label, index) => (
                        <Step
                            key={`${label}-${index}`}
                            sx={{
                                '& MuiStep-alternativeLabel': {
                                    width: '80%',
                                },
                            }}
                            data-testid={`step-${label}`}
                        >
                            <StepLabel>
                                <FormattedMessage id={`layout.${label.toUpperCase()}`} />
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            )}

            {/* Main */}
            <Div
                role="main"
                id="main"
                sx={{
                    display: 'block',
                    padding: '82px 16px',
                    maxWidth: 1450,
                    margin: 'auto',
                    height: '100%',
                }}
            >
                {(loadingOdstavka || loadingProfil) && <LoadingDialog open />}
                {!odstavka && !loadingOdstavka && !loadingProfil && children}
                {odstavka && !loadingOdstavka && !loadingProfil && (
                    <FormOff message={formKind?.VYJADROVACKA[komodita ?? Komodita.ELEKTRINA]?.formZakazanHtml} refetch={handleRefresh} />
                )}
            </Div>

            {/* Footer */}

            <Div
                sx={{
                    width: '100%',
                    height: 86,
                    left: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: 'secondary.main',
                    background: komodita === Komodita.ELEKTRINA ? 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)' : undefined,
                }}
            >
                <Div
                    sx={(theme) => ({
                        paddingTop: theme.spacing(4),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 1,
                        maxWidth: 1680,
                        margin: 'auto',
                    })}
                >
                    {komodita === Komodita.ELEKTRINA && <EgdLogo width={100} height={33} pathFill={theme.palette.background.default} />}
                    {komodita === Komodita.PLYN && <GasLogo width={100} height={33} pathFill={theme.palette.background.default} />}
                    {komodita && (
                        <Typography
                            component={'span'}
                            variant="body2"
                            sx={{
                                color: 'background.default',
                            }}
                        >
                            <FormattedMessage id={komodita === Komodita.ELEKTRINA ? 'footer' : 'footer.gas'} values={{year: new Date().getFullYear()}} />
                        </Typography>
                    )}
                </Div>
            </Div>
            {isOpenUserDialog && <OsobniUdajeZakaznikDialog onClose={handleOnCloseDetailDialog} />}
        </Div>
    );
};
