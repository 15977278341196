import {ApolloClient} from '@apollo/client';
import axios from 'axios';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {ErrorResponse, getErrorMessage, redirect, removeCookie, setCookie} from '../../auth/service/LoginService';
import {RootActionType} from '../../lib/rootAction';
import {NotificationType} from '../../models/notification/NotificationModel';
import {BackendEndpoints} from '../BackendEndpoints';
import {addNotification} from '../components/notifications/reducer/NotificationSlice';

import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {AnyAction, bindActionCreators, Dispatch, UnknownAction} from '@reduxjs/toolkit';
import Router from 'next/router';
import store from '../../lib/store';
import {PageRoute, redirectURI} from '../constants';
import {
    errorfetchedLogin,
    errorfetchedLoginConfig,
    fetchedLogin,
    fetchingLogin,
    loginConfigFetched,
    loginConfigFetching,
    logouting,
    setActiveStep,
    setFormData,
    setFormKindSlice,
    setKomoditaSlice,
    setKomoditaVyberSlice,
    setSelfcareLogin,
    setSuccessStep,
} from '../reducers/CommonSlice';
import {VyjadrovackaInputCustomData} from '../types';
import {FormKind, ValidationErrorType} from '../utils/CommonTypes';

type AuthenticateResponse = {
    readonly data: {
        readonly expiresIn: number;
        readonly token: string;
    };
};

type LoginConfigResponse = {
    readonly data: {
        readonly loginDisabled: boolean;
    };
};
type CommonActionType = {
    logout: (client: ApolloClient<any>, error?: ErrorResponse | undefined) => void;
    resetFormData: (komodita: Komodita | null | undefined) => void;
    setFormData: (data: Partial<VyjadrovackaInputCustomData>) => void;
    setActiveStep: (step: number) => void;
    setSuccessStep: (step: number) => void;
    fetchLogin: (credentials: {code: string | null; typ?: 'OAUTH' | 'LOGIN'; komoditaVyber?: boolean | null}) => void;
    fetchLoginConfig: () => void;
    setSelcare: (selfcare: boolean) => void;
    backToD24: (client: ApolloClient<any>) => void;
    setKomoditaAction: (komodita: Komodita | null | undefined) => void;
    setFormKind: (value: FormKind | undefined) => void;
    setKomoditaVyber: (value: boolean | null) => void;
};

/**
 * It sets a cookie and redirects to the root page.
 * @param {string} token - The token that you get from the server.
 * @param {number} expiresIn - number - The number of seconds until the cookie expires.
 */
const doLogin = async (expiresIn: number, dispatch: Dispatch<UnknownAction>, komoditaVyber?: boolean | null) => {
    setCookie(expiresIn);
    if (komoditaVyber) {
        await redirect({pathname: PageRoute.VSTUP}).then(() => {
            dispatch(setActiveStep(0));
            dispatch(setKomoditaSlice(undefined));
        });
    } else {
        dispatch(setActiveStep(1));
        dispatch(setSuccessStep(0));
        await redirect({pathname: PageRoute.OSOBNI_UDAJE});
    }
};

/**
 * It takes an error string and returns a message string
 * @param error - The error code returned by the server.
 * @returns the value of the key in the object.
 */
const setErrorMessage = (error: 'INVALID_CLIENT') => {
    const errorMessage = {
        ['INVALID_CLIENT']: 'Špatný client ID.',
    };
    return errorMessage[error] ?? 'Chyba přihlášení. Zkuste to prosím později.';
};

/**
 * It returns an object with functions that dispatch actions to the Redux store
 */
export const useCommonAction = (): CommonActionType => {
    const dispatch = useDispatch();
    const komodita = store.getState().common.komodita;
    return {
        setFormKind: (value) => dispatch(setFormKindSlice(value)),
        setKomoditaVyber: (value: boolean | null) => dispatch(setKomoditaVyberSlice(value)),
        logout: (client: ApolloClient<any>, error) => {
            dispatch(logouting(true));
            const routeParams = error ? {pathname: PageRoute.VSTUP, query: {error}} : {pathname: PageRoute.VSTUP};
            // waiting for reset apollo
            if (typeof window !== 'undefined') {
                axios.post(`${window?.location?.origin}/api/logout`).then(() =>
                    client.cache
                        .reset()
                        .then(() => {
                            removeCookie();
                            redirect(routeParams)
                                .then(() => {
                                    // Reset store
                                    dispatch({type: RootActionType.LOGOUT});
                                    dispatch(setKomoditaSlice(komodita));
                                    dispatch(logouting(false));
                                    dispatch(setActiveStep(0));
                                    const errorMessage = getErrorMessage(error as ErrorResponse);
                                    if (error)
                                        dispatch(
                                            addNotification({
                                                type: NotificationType.ERROR,
                                                text: <FormattedMessage id={errorMessage} />,
                                            }),
                                        );
                                    dispatch(addNotification({type: NotificationType.WARNING, text: <FormattedMessage id="common.logout" />}));
                                })
                                .catch((error) => new Error(error))
                                .finally(() => dispatch(logouting(false)));
                        })
                        .catch((error) => new Error(error)),
                );
            }
        },
        fetchLogin: ({code, typ, komoditaVyber}) => {
            dispatch(fetchingLogin());

            axios
                .post<unknown, AuthenticateResponse>(
                    `${window.location.origin}/api/authenticate`,
                    {
                        typ: typ || 'OAUTH',
                        code,
                    },
                    {
                        validateStatus: (status) => status >= 200 && status <= 302,
                    },
                )
                .then((res) => {
                    dispatch(fetchedLogin());
                    doLogin(res.data.expiresIn, dispatch, komoditaVyber);
                })
                .catch((err) => {
                    if ([401, 403].includes(err.response?.status)) {
                        redirect({pathname: PageRoute.VSTUP})
                            .then(() => {
                                dispatch(
                                    errorfetchedLogin({
                                        message: setErrorMessage(err.response?.data?.error),
                                        code: err.response.data.error ?? 'CUSTOM',
                                        type: ValidationErrorType.WARNING,
                                    }),
                                );
                            })
                            .catch((error) => new Error(error))
                            .finally(() => dispatch(addNotification({type: NotificationType.WARNING, text: setErrorMessage(err.response?.data?.error)})));
                    }
                    if (err.response?.status === 500) {
                        dispatch(
                            errorfetchedLogin({
                                code: 'CUSTOM',
                                message: 'Chyba na straně serveru.',
                                type: ValidationErrorType.CRITICAL,
                            }),
                        );
                    }
                });
        },
        fetchLoginConfig: () => {
            dispatch(loginConfigFetching());
            axios
                .get<unknown, LoginConfigResponse>(`${window.location.origin}/api/${BackendEndpoints.LOGIN_CONFIG}`)
                .then((res) => {
                    dispatch(loginConfigFetched(res.data.loginDisabled));
                })
                .catch(() => {
                    dispatch(
                        errorfetchedLoginConfig({
                            code: 'CUSTOM',
                            message: 'Chyba na straně serveru.',
                            type: ValidationErrorType.CRITICAL,
                        }),
                    );
                });
        },
        setSelcare: (selfcare) => {
            dispatch(setSelfcareLogin(selfcare));
        },
        resetFormData: (komodita) => {
            dispatch({type: RootActionType.LOGOUT, payload: undefined});
            dispatch(setKomoditaSlice(komodita));
        },

        setFormData: (data) => {
            dispatch(setFormData(data));
        },
        setActiveStep: (step) => {
            dispatch(setActiveStep(step));
        },
        setSuccessStep(step) {
            dispatch(setSuccessStep(step));
        },
        setKomoditaAction(komodita) {
            dispatch(setKomoditaSlice(komodita));
        },
        backToD24: (client: ApolloClient<any>) => {
            client.cache
                .reset()
                .then(() => {
                    window.sessionStorage.clear();
                    window.localStorage.clear();
                    Router.push(redirectURI);
                })
                .catch((error) => new Error(error));
        },
    };
};

const CommonActionsImpl = {
    logout: (client: ApolloClient<any>, error?: string) => (dispatch: Dispatch) => {
        dispatch(logouting(true));
        // waiting for reset apollo
        if (typeof window !== 'undefined') {
            axios.post(`${window?.location?.origin}/api/logout`).then(() =>
                client.cache
                    .reset()
                    .then(() => {
                        removeCookie();

                        // Reset store
                        dispatch({type: RootActionType.LOGOUT});
                        dispatch(logouting(false));
                        const errorMessage = getErrorMessage(error as ErrorResponse);
                        if (error)
                            dispatch(
                                addNotification({
                                    type: NotificationType.ERROR,
                                    text: <FormattedMessage id={errorMessage} />,
                                }),
                            );
                    })
                    .catch((error) => new Error(error)),
            );
        }
    },
};

export const CommonActionCreator = (dispatch: Dispatch<AnyAction>) => bindActionCreators({...CommonActionsImpl}, dispatch);
